#custom_privacyPolict .sc-gXSCqU.bRomnS a,
#custom_privacyPolict .hOlQoX {
    color: black !important;
}

#custom_privacyPolict .eIUZmC {
    background: unset !important;
    height: 35vh !important;
}

#custom_privacyPolict .gxoXdv {
    background-image: unset !important;
}

.privacyPolicy_data {
    width: 65%;
    margin: 0 auto;
    padding: 100px 20px;
}