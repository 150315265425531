.hidden {
    opacity: 0;
    filter: blur(5px);
    /* translate:  0 -100%; */
    transition: all 1s;
}
.show {
    opacity: 1;
    filter: blur(0);
    /* translate: 0 0; */
    transition: all 1s;
}
